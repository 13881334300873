import moment from 'moment';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PortfolioContext from '../../../context/context';
import { sendEvent } from '../../../utils/ga4';
import MainWidth from '../../Common/MainWidth';

const DataLabel = ({ data }: { data: string }) => {
  let postDate = data;
  if (postDate.split(' ').length === 2) {
    postDate += `,  ${new Date().getFullYear()}`;
  }
  return <>{`${moment(postDate).format('D MMM YYYY')} `}</>;
};
interface IBlogAuthor {
  author?: string;
  avatar?: string;
}
const BlogAuthor = ({ author, avatar }: IBlogAuthor) => {
  if (!author) return null;
  return (
    <div className="author">
      {avatar && <img src={avatar} alt={author} className="avatar" />}
      <p className="author-text">
        <span>Written by:</span>
        <span><b>{author}</b></span>
      </p>
    </div>
  );
};

interface Article {
  id: string;
  title: string;
  description?: string;
  SecondDescription?: string;
  thumbnail?: string;
  SecondThumbnail?: string;
  link?: string;
  SecondLink?: string;
  avatar?: string;
  author?: string;
  pubDate?: string;
  readingTime?: string;
}

interface Props {
  articles: Article[];
}

const Blog = ({ articles }: Props) => {
  const { bootstrapBreakpoints } = React.useContext(PortfolioContext);
  var description =
    'Staff Engineer is a term that has been used mainly by the FAANG companies or similar highly tech focused companies. It is one of the steps…';

  const breakpointColumnsObj = {
    default: 2,
    [bootstrapBreakpoints.lg]: 1,
  };

  const handleBlogArticleClick = (url:string | undefined) => {
    if(!url) return;

    sendEvent({
      action: "Click",
      category: "Blog Article",
      label: `${url}`
    });
  }

  if (!articles || articles.length == 0) {
    return null;
  }

  return (
    <section id="blog-section" className="page-section">
      <span id="bloganchor"></span>
      <MainWidth>
        <Container fluid className="nopadding">
          <Row className="blog_container justify-content-md-center nopadding">
            <h1 className="blog_title title-font-size">Blog</h1>
            <div className={`blog__grid blog__grid-${articles.length > 2 ? 0 : articles.length}`}>
              {articles.map((item) => (
                <a
                  key={item.id}
                  href={item.link ? item.link : item.SecondLink}
                  className="link_blog"
                >
                  <div className="blog__grid-card">
                    <img
                      src={
                        item.thumbnail
                          ? item.thumbnail.substring(5, item.thumbnail.length - 2)
                          : item.SecondThumbnail
                      }
                      style={{
                        objectPosition:
                          item.description.length === description.length
                            ? 'right'
                            : 'initial',
                      }}
                      className="img"
                      alt="boutique software, software house porto, software house aveiro,nearshore"
                    />
                    <div className="info">
                      <figcaption>
                        <DataLabel data={item.pubDate} />
                        <span className="dot" /> <span>{item.readingTime}</span>
                      </figcaption>
                      <h3 className="title">{item.title}</h3>
                      <div className="text">
                        {item.description ? item.description : item.SecondDescription}
                      </div>
                      <BlogAuthor avatar={item.avatar} author={item.author} />
                    </div>
                  </div>
                </a>
              ))}
            </div>
            <div className="buttonContainer">
              <a
                href="https://medium.com/craftable"
                className="SeeAllButton"
                style={{ display: articles.length >= 5 ? 'inline-block' : 'none' }}
              >
                See all posts
              </a>
            </div>
          </Row>
        </Container>
      </MainWidth>
    </section>
  );
};

export default Blog;

import React from 'react';

const HandShake = () => (
  <svg
    version="1.1"
    id="aboutImage"
    x="0px"
    y="0px"
    viewBox="0 0 259 234"
    className="inst-wrapper__image"
  >
    <path
      className="st0"
      d="M230.8,26.9c-14-14-32.6-21.7-52.3-21.7c-18.1,0-35.2,6.5-48.7,18.3C116.2,11.6,99,5.1,80.8,5.1
            c-19.8,0-38.4,7.7-52.4,21.7c-14,14-21.7,32.6-21.7,52.4c0,19.8,7.7,38.4,21.7,52.4c0,0,0,0,0,0c-0.1,0.7-0.1,1.4-0.1,2.1
            c0,6,2.3,11.5,6.5,15.7c4.3,4.3,10,6.5,15.7,6.5c0.8,0,1.5,0,2.3-0.1c-0.1,0.8-0.1,1.5-0.1,2.3c0,6,2.3,11.5,6.5,15.7
            c4.3,4.3,10,6.5,15.7,6.5c0.8,0,1.5,0,2.3-0.1c-0.1,0.8-0.1,1.5-0.1,2.3c0,6,2.3,11.5,6.5,15.7c4.3,4.3,10,6.5,15.7,6.5
            c0.8,0,1.5,0,2.3-0.1c-0.1,0.8-0.1,1.5-0.1,2.3c0,6,2.3,11.5,6.5,15.7c4.2,4.2,9.8,6.5,15.7,6.5c6,0,11.5-2.3,15.7-6.5l16.3-16.3
            c0.6-0.6,1.1-1.1,1.6-1.7c0.8,0.1,1.6,0.2,2.4,0.2c6,0,11.6-2.3,15.8-6.5c4.9-4.9,7-11.5,6.4-18c6.5,0.6,13.1-1.5,18-6.4
            c4.2-4.2,6.5-9.8,6.5-15.8c0-0.7,0-1.5-0.1-2.2c6.5,0.6,13.1-1.5,18-6.4c4.9-4.9,7-11.6,6.4-18C259.6,102.7,259.6,55.7,230.8,26.9z
            M223.7,33.9c23.8,23.8,24.9,61.7,3.4,86.9l-69.5-69.5c-0.2-0.2-0.5-0.4-0.8-0.6c0,0-0.1,0-0.1-0.1c-0.2-0.1-0.5-0.3-0.7-0.4
            c-0.2-0.1-0.4-0.1-0.7-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.4-0.1-0.7-0.1c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c0,0,0,0,0,0
            c-0.1,0-0.3,0-0.4,0c-0.2,0-0.4,0-0.5,0.1c-0.2,0-0.3,0.1-0.4,0.1c-0.2,0.1-0.3,0.1-0.5,0.2c-0.1,0.1-0.3,0.1-0.4,0.2
            c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0.1-0.3,0.2-0.4,0.3l-24.4,24.4c-2.3,2.3-5.4,3.6-8.7,3.6
            c-3.3,0-6.4-1.3-8.7-3.6c-4.8-4.8-4.8-12.6,0-17.3L133.1,34c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c12.1-12,28.1-18.7,45.2-18.7
            C195.5,15.2,211.6,21.8,223.7,33.9z M42,142.4c-2.3-2.3-3.6-5.4-3.6-8.7s1.3-6.4,3.6-8.7l16.3-16.3c2.3-2.3,5.4-3.6,8.7-3.6
            c3.3,0,6.4,1.3,8.7,3.6c4.8,4.8,4.8,12.6,0,17.3l-16.3,16.3C54.5,147.2,46.8,147.2,42,142.4z M66.4,166.8c-2.3-2.3-3.6-5.4-3.6-8.7
            s1.3-6.4,3.6-8.7l16.3-16.3c2.4-2.4,5.5-3.6,8.7-3.6c3.1,0,6.3,1.2,8.7,3.6l0,0c2.3,2.3,3.6,5.4,3.6,8.7s-1.3,6.4-3.6,8.7
            l-16.3,16.3C78.9,171.6,71.2,171.6,66.4,166.8z M90.8,191.2c-2.3-2.3-3.6-5.4-3.6-8.7s1.3-6.4,3.6-8.7l16.3-16.3
            c2.4-2.4,5.5-3.6,8.7-3.6c3.1,0,6.3,1.2,8.7,3.6c2.3,2.3,3.6,5.4,3.6,8.7s-1.3,6.4-3.6,8.7l-16.3,16.3
            C103.3,196,95.6,196,90.8,191.2z M132.5,215.6c-2.3,2.3-5.4,3.6-8.7,3.6c-3.3,0-6.4-1.3-8.7-3.6c-2.3-2.3-3.6-5.4-3.6-8.7
            s1.3-6.4,3.6-8.7l16.3-16.3c2.3-2.3,5.4-3.6,8.7-3.6s6.4,1.3,8.7,3.6h0c3.8,3.8,4.6,9.5,2.3,14.1c-0.1,0.2-0.2,0.3-0.3,0.5l-0.1,0.1
            c-0.1,0.2-0.2,0.4-0.3,0.6c-0.5,0.7-1,1.4-1.6,2.1L132.5,215.6z M217.2,142.4c-4.8,4.8-12.5,4.8-17.3,0L153.6,96
            c-1.9-1.9-5.2-1.9-7.1,0c-0.9,0.9-1.5,2.2-1.5,3.5s0.5,2.6,1.5,3.6l46.4,46.4c2.3,2.3,3.6,5.4,3.6,8.6c0,3.3-1.3,6.3-3.6,8.6
            c-2.3,2.3-5.4,3.6-8.6,3.6c0,0,0,0,0,0c-3.3,0-6.3-1.3-8.6-3.6l-46.4-46.4c-0.9-0.9-2.2-1.4-3.6-1.4c0,0,0,0,0,0
            c-1.3,0-2.6,0.6-3.6,1.5c-1.9,2-1.9,5.1,0,7.1l46.4,46.4c4.8,4.8,4.8,12.5,0,17.3c-1.8,1.8-4,2.9-6.4,3.3c1.2-6.9-0.8-14.3-6.2-19.6
            v0c-4.9-4.9-11.6-7-18-6.4c0.1-0.8,0.1-1.5,0.1-2.3c0-6-2.3-11.5-6.5-15.7v0c-4.9-4.9-11.6-7-18-6.4c0.1-0.8,0.1-1.5,0.1-2.3
            c0-6-2.3-11.5-6.5-15.7c-4.9-4.9-11.6-7-18-6.4c0.7-6.4-1.5-13.1-6.4-18c-4.2-4.2-9.8-6.5-15.7-6.5c-6,0-11.5,2.3-15.7,6.5L34.9,118
            c-0.9,0.9-1.7,1.9-2.5,3c-10.1-11.6-15.6-26.3-15.6-41.8c0-17.1,6.7-33.2,18.7-45.2c12.1-12.1,28.1-18.7,45.2-18.7
            c15.4,0,30,5.4,41.5,15.3l-20.7,20.7c-8.7,8.7-8.7,22.8,0,31.5c8.7,8.7,22.8,8.7,31.5,0L154,61.9l63.2,63.2
            C222,129.9,222,137.6,217.2,142.4z"
    />
  </svg>
);

export default HandShake;

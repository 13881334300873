import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import MainWidth from '../../Common/MainWidth';
import Icon from '../../Common/Icon';
import { sendEvent } from '../../../utils/ga4';

import PortfolioContext from '../../../context/context';

gsap.registerPlugin(ScrollTrigger);

const Contacts = () => {
  const { contact, animationConfig } = React.useContext(PortfolioContext);
  const { contactsTitle, contacts, offices } = contact;

  React.useEffect(() => {
    gsap.from('.contacto-container ', {
      ...animationConfig,
      scrollTrigger: {
        trigger: '.contacto-container ',
        start: 'top 100%',
        end: 'bottom 0%',
        toggleActions: 'play none none none',
      },
    });
  }, []);

  const handleLinkClick = (message) => {
    if(!message || message === '') return;

    sendEvent({
      action: "Click",
      category: `${message} on Contacts`,
      label: "Link"
    });
  }

  return (
    <section id="contacts">
      <span id="contactsanchor"></span>
      <MainWidth>
        {/* overflow-hidden */}
        <Container className="inst-section__container   nopadding" fluid>
          <Row className="inst-section__wrapper_contacts inst-wrapper nopadding">
            <Col className="nopadding-mobile">
              <Container className="contacts_header contacto-container " fluid>
                <Col lg={6} md={12} className="nopadding-mobile">
                  <div className="contacts_col-right">
                    <h2 className="title-font-size contacts__title">{contactsTitle}</h2>
                    {contacts.map((c) => {
                      const { idx, icon, contact: contactC, href, analytics } = c;
                      return (
                        <div key={idx} className="contacts_row">
                          <a 
                            href={href + contactC} 
                            id={icon} 
                            className="contacts__txt"
                            onClick={() => handleLinkClick(analytics)}
                          >
                            <i className="contacts_icon-holder d-flex justify-content-center align-items-center">
                              <Icon name={icon} />
                            </i>
                            {/* <img alt='contact' src={icon} /> */}
                            {contactC}
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </Col>
                <Col lg={6} md={12} className="nopadding-mobile">
                  <span id="startproject"></span>
                  <div className="contacts_col-left">
                    {offices.map((office) => (
                      <div key={office.idx} className="contacts_row">
                        <h5 className="contacts_offices"> {office.name}</h5>
                        <p className="contacts_address"> {office.address}</p>
                      </div>
                    ))}
                  </div>
                </Col>
              </Container>
            </Col>
          </Row>
        </Container>
      </MainWidth>
    </section>
  );
};

export default Contacts;

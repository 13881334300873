import React, { useCallback, useEffect } from 'react';

const Svg = () => {
  const configs = [
    {
      layout: 'mobile',
      treshold: '481px',
      radiusLeaf: 0.2,
      radiusCorner: 0.07,
      roundEffectLeaf: 0.95,
      leafCoef: 3.4,
      widthContainerLine: 0.4,
      distanceBwetweenLeafs: 0.1,
      strokeWidth: '10px',
    },
    {
      layout: 'ipad',
      treshold: '769px',
      radiusLeaf: 0.15,
      radiusCorner: 0.07,
      roundEffectLeaf: 0.95,
      leafCoef: 3.4,
      widthContainerLine: 0.4,
      distanceBwetweenLeafs: 0.1,
      strokeWidth: '15px',
    },
    {
      layout: 'desktop',
      radiusLeaf: 0.075,
      radiusCorner: 0.0375,
      roundEffectLeaf: 0.875,
      leafCoef: 3.7,
      widthContainerLine: 0.4,
      distanceBwetweenLeafs: 0.05,
      strokeWidth: '15px',
      maxWidth: 720,
    },
  ];

  const getTartgetBreakpoint = () => {
    let returnConfig;
    for (let it = 0; it < configs.length; it += 1) {
      const config = configs[it];
      if (!Object.prototype.hasOwnProperty.call(config, 'treshold')) {
        returnConfig = config;
        break;
      }
      if (window.matchMedia(`(max-width: ${config.treshold})`).matches) {
        returnConfig = config;
        break;
      }
    }
    return returnConfig;
  };

  const mountSVG = useCallback((redraw = false) => {
    const width = document.body.clientWidth;
    const hero = document.getElementById('hero');
    if (hero) {
      const firstDivHeight = hero.clientHeight;
      const targetBP = getTartgetBreakpoint();
      const posXini = width / 2;
      let radiusLeaf = width * targetBP.radiusLeaf;
      let radiusCorner = width * targetBP.radiusCorner;
      if (targetBP.layout == 'desktop') {
        if (radiusCorner < 50) {
          radiusCorner = 50;
        }
        if (radiusLeaf < 100) {
          radiusLeaf = 100;
        }
        if (radiusCorner > 72) {
          radiusCorner = 72;
        }
        if (radiusLeaf > 144) {
          radiusLeaf = 144;
        }
      }

      let widthLine = width * targetBP.widthContainerLine;
      // eslint-disable-next-line no-prototype-builtins
      if (targetBP.hasOwnProperty('maxWidth') && widthLine > targetBP.maxWidth) {
        widthLine = targetBP.maxWidth;
      }
      if (widthLine > 550) {
        widthLine = 550;
      }
      const posY = firstDivHeight - 20 - radiusCorner * 2;
      let distanceBwetweenLeafs = width * targetBP.distanceBwetweenLeafs;
      if (targetBP.layout == 'desktop') {
        if (distanceBwetweenLeafs < 66) {
          distanceBwetweenLeafs = 66;
        }
        if (distanceBwetweenLeafs > 96) {
          distanceBwetweenLeafs = 96;
        }
      }
      const finalY = document.getElementById('about-holder').clientHeight + firstDivHeight;
      const heightLeaf = Math.sqrt(radiusLeaf ** 2 + radiusLeaf ** 2);
      const incYCenterLeaf = heightLeaf / 3;
      const posNewY = posY - incYCenterLeaf;

      const projectsHeight = document.getElementById('projects-holder').clientHeight;
      const lastLeafHeight = finalY + projectsHeight;
      const middleLeaf = posY - heightLeaf + heightLeaf / 6;
      for (let i = 0; i < document.getElementsByClassName('path').length; i += 1) {
        const path = document.getElementsByClassName('path')[i];
        path.setAttribute('stroke-width', targetBP.strokeWidth);
      }
      const pathRight = `M ${posXini + distanceBwetweenLeafs}, ${posY}
                C ${posXini + distanceBwetweenLeafs + radiusLeaf + radiusLeaf / 6} ${posY}, ${
        posXini + distanceBwetweenLeafs + radiusLeaf
      } ${posY - radiusLeaf}, ${posXini + distanceBwetweenLeafs + radiusLeaf} ${posY - radiusLeaf}
                C ${posXini + distanceBwetweenLeafs - radiusLeaf / 6} ${posY - radiusLeaf}, ${
        posXini + distanceBwetweenLeafs
      } ${posY}, ${posXini + distanceBwetweenLeafs} ${posY}
                a ${radiusCorner} ${radiusCorner} 0 0,0 ${radiusCorner},${radiusCorner}
                            L ${posXini + widthLine} ${posY + radiusCorner}
                a ${radiusCorner} ${radiusCorner} 0 0,1 ${radiusCorner},${radiusCorner}
                L ${posXini + widthLine + radiusCorner} ${finalY}`;

      const leafRight = document.getElementById('leaf_right');
      if (leafRight) {
        leafRight.setAttribute('d', pathRight);
      }

      const pathCenter = `M ${posXini} ${posNewY}
                C ${posXini - heightLeaf / 3} ${middleLeaf + heightLeaf / 6},  ${
        posXini - heightLeaf / 3
      } ${middleLeaf - heightLeaf / 6}, ${posXini} ${posNewY - heightLeaf}
                C ${posXini + heightLeaf / 3} ${middleLeaf - heightLeaf / 6},  ${
        posXini + heightLeaf / 3
      } ${middleLeaf + heightLeaf / 6}, ${posXini} ${posNewY}
                L ${posXini} ${firstDivHeight + 50}`;
      document.getElementById('leaf_center').setAttribute('d', pathCenter);
      const pathLeft = `M ${posXini - distanceBwetweenLeafs}, ${posY}
                C ${posXini - distanceBwetweenLeafs - radiusLeaf - radiusLeaf / 6} ${posY}, ${
        posXini - distanceBwetweenLeafs - radiusLeaf
      } ${posY - radiusLeaf}, ${posXini - distanceBwetweenLeafs - radiusLeaf} ${posY - radiusLeaf}
                C ${posXini - distanceBwetweenLeafs + radiusLeaf / 6} ${posY - radiusLeaf}, ${
        posXini - distanceBwetweenLeafs
      } ${posY}, ${posXini - distanceBwetweenLeafs} ${posY}
                a ${radiusCorner} ${radiusCorner} 0 0,1 -${radiusCorner},${radiusCorner}
                L ${posXini - widthLine} ${posY + radiusCorner}
                a ${radiusCorner} ${radiusCorner} 0 0,0 -${radiusCorner},${radiusCorner}
                L ${posXini - widthLine - radiusCorner} ${lastLeafHeight}`;
      document.getElementById('leaf_left').setAttribute('d', pathLeft);

      const svgContainer = document.getElementById('svg_container');
      if (svgContainer) {
        // svgContainer.style.height = `${window.innerHeight - heightLeaf}px`;
      }

      if (redraw) {
        mountSVG();
      }
    }
  }, []);

  useEffect(() => {
    const svgAnimation = document.getElementById('svg_animation');
    if (svgAnimation) {
      setTimeout(() => {
        svgAnimation.style.opacity = '1';
        mountSVG(true);
      }, 4000);
      window.addEventListener('resize', mountSVG);
    }
  }, []);

  return (
    <svg
      id="svg_animation"
      width="100%"
      height="100%"
      preserveAspectRatio="none"
      style={{ opacity: 0 }}
    >
      <path
        id="leaf_right"
        className="path"
        fill="none"
        strokeWidth="1vw"
        stroke="#47ffbf"
        strokeLinejoin="miter"
        strokeLinecap="square"
        strokeMiterlimit="3"
        vectorEffect="non-scaling-stroke"
      />
      <path
        id="leaf_center"
        className="path"
        fill="none"
        strokeWidth="1vw"
        stroke="#52d6f0"
        strokeLinejoin="miter"
        strokeLinecap="square"
        strokeMiterlimit="3"
        vectorEffect="non-scaling-stroke"
      />
      <path
        id="leaf_left"
        className="path"
        fill="none"
        strokeWidth="1vw"
        stroke="white"
        strokeLinejoin="miter"
        strokeLinecap="square"
        strokeMiterlimit="3"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  );
};

export default Svg;

import HomePage from '../components/HomePage';
import React, {useEffect} from 'react';
import Layout from '../components/Common/Layout';
import Seo from '../components/Common/Seo';
import { initializeGa4,sendPageView } from '../utils/ga4';
import { initializeHotjar } from '../utils/hotjar';

export default (props: any) => {
  useEffect(() => {
    initializeHotjar();
    initializeGa4();
    sendPageView({
      page: "/", 
      title:"Homepage"
    });
  }, []);

  return (
    <Layout hash={props?.location?.hash}>
      <Seo
        title="software with purpose"
        description="craftable software specialises in systems that require quality, reliability and scalability in a highly transactional environment for complex software ecosystems."
        keywords="software development,  technology solutions, technology services, nearshore, enear, IT projects, IT specialist, Portugal, traveltech, fintech,healthtech,hospitality, ecommerce, cloud, cybersecurity, Infrastructure as code, AWS, Azure, GCP, Kubernetes, Docker, .NET core, Java, Spring, NodeJS, React, Agile, SCRUM, User Experience UX and Usability, UX/UI, boutique software, software house porto, software house aveiro"
      />
      <HomePage />
    </Layout>
  )
};

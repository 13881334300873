export interface IBreakpoints {
  sm: string;
  md: string;
  lg: string;
  xl: string;
  xxl: string;
}

export const breakpoints: IBreakpoints = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',
};

export const pxTovwConverter = (viewportWidth: number, sizeInPx: number): string => {
  return `${sizeInPx * (100 / viewportWidth)}vw`;
};

export default { breakpoints };

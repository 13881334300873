import React from 'react';
import HandShake from './HandShake';
import TeamWork from './TeamWork';
import Cool from './Cool';

export interface IInstitutionalImage {
  image: string;
  className?: string;
}

const InstitutionalImage = ({ image }: IInstitutionalImage) => {
  const components = {
    about: HandShake,
    projects: TeamWork,
    contact: Cool,
  };
  const TagName = components[image];
  return <TagName />;
};

export default InstitutionalImage;

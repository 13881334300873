import styled from 'styled-components';
import { breakpoints } from '../../../utils/breakpoints';

export const TimelineSection = styled.section`
  padding: 0 0 var(--spacer-2);
  background-color: var(--block-bg-color);
  position: relative;
  z-index: 9;
`;
export const VideoContainer = styled.div`
  display: block;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: var(--spacer-1) 0 var(--spacer-1) * 2 0;
  }
`;
export const TimelineContainer = styled.div`
  position: relative;
  margin: 0;
  &::before {
    top: 0;
    left: 50%;
    width: 30px;
    height: 30px;
    border-radius: 20px;
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    background: var(--block-bg-color);
    border: 4px solid var(--primary-bg-color);
    transform: translate(-50%, -50%);

    @media only screen and (max-width: ${breakpoints.md}) {
      display: none;
    }
  }
  &::after {
    top: 100%;
    left: 50%;
    width: 30px;
    height: 30px;
    content: '';
    display: block;
    position: absolute;
    border-radius: 20px;
    z-index: 1;
    transform: translate(-50%, -50%);

    @media only screen and (max-width: ${breakpoints.md}) {
      display: none;
    }
  }
`;
export const VerticalTimeline = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  &::before {
    content: '';
    border-radius: 4px;
    background-color: var(--primary-bg-color);
    position: absolute;
    top: 0;
    height: 80%;
    width: 4px;
    left: 50%;
    margin-left: -2px;
    @media only screen and (min-width: 1550px) {
      height: 80%;
    }
    @media only screen and (max-width: 992px) {
      height: 78%;
    }
    @media only screen and (max-width: ${breakpoints.md}) {
      display: none;
    }
  }
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
  position: relative;
  padding: 2em 0 0;
`;
export const Step = styled.span`
  position: absolute;
  right: 0;
  padding-right: 80px;

  top: 50%;
  color: #fff;
  font-weight: 600;
  position: absolute;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: calc(1.2525rem + 0.16333vw) !important;
  letter-spacing: 0.0625rem;
  white-space: nowrap;

  @media only screen and (max-width: ${breakpoints.md}) {
    display: none;
  }
`;
export const Step4 = styled.span`
  position: absolute;
  right: 56%;
  top: 38%;
  color: #fff;
  font-weight: 600;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: calc(1.2525rem + 0.16333vw) !important;
  letter-spacing: 0.0625rem;
  white-space: nowrap;
  @media only screen and (max-width: 1439px) and (min-width: 1025px) {
    top: 155px;
  }
  @media only screen and (max-width: 1024px) {
    top: 32%;
  }
  @media only screen and (max-width: ${breakpoints.lg}) {
    display: none;
  }
`;
export const TimelineLi = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  margin: 4em 0;

  &:nth-child(odd) ${Step} {
    right: unset;
    left: 0;
    padding-left: 80px;
  }
`;
export const TimelineLicta = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  margin: 8em 0 -5em;

  &:nth-child(odd) ${Step} {
    right: unset;
    left: 0;
    padding-left: 80px;
  }
`;
export const TopBlock = styled.div`
  position: relative;
  width: 44%;

  @media only screen and (max-width: ${breakpoints.md}) {
    width: 50%;
    padding-bottom: 1rem;

    svg {
      width: 100%;
    }
  }
`;
export const ButtonBlock = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  top: -100px;

  @media only screen and (max-width: ${breakpoints.md}) {
    width: 50%;
    padding-bottom: 1rem;

    svg {
      width: 100%;
    }
  }
`;
export const BottomBlock = styled.div`
  position: relative;
  width: 44%;
  padding: 40px;
  border-radius: 15px;
  color: #31429a;
  margin-left: 0;
  background: #fff;

  @media only screen and (max-width: ${breakpoints.md}) {
    width: 90%;
  }
`;

export const TimelineIcon = styled.div`
  @media only screen and (max-width: ${breakpoints.lg}) {
    display: none;
  }
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 4px solid var(--primary-bg-color);
  position: relative;
  background-color: var(--block-bg-color);
  svg {
    top: 50%;
    left: 50%;
    width: 25px;
    height: 15px;
    display: block;
    position: absolute;
    transform: translate(-50%, -40%);
  }
`;

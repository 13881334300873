import { nanoid } from 'nanoid';

export const title = `Technical values we never compromise on`;

export const technicalValues = [
  {
    id: nanoid(),
    icon: 'peer-review',
    value: 'Peer\nReview',
  },
  {
    id: nanoid(),
    icon: 'continuous-integration',
    value: 'Continuous\nIntegration/Delivery',
  },
  {
    id: nanoid(),
    icon: 'continuous-delivery',
    value: 'Infrastructure\nas Code',
  },
  {
    id: nanoid(),
    icon: 'agile-principles',
    value: 'Agile\nManifesto',
  },
  {
    id: nanoid(),
    icon: 'usability-heuristics',
    value: 'Usability\nHeuristics',
  },
  {
    id: nanoid(),
    icon: 'quality-assurance',
    value: 'Quality\nAssurance',
  },
];

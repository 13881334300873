import React from 'react';
import { nanoid } from 'nanoid';
import { Container, Row, Col } from 'react-bootstrap';
// import ScrollIcon from '../../assets/icons/scrollIcon.svg'

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import MainWidth from '../../Common/MainWidth';
import JobCard from '../JobCard';
import Icon from '../../Common/Icon';
import { sendEvent } from '../../../utils/ga4';

import PortfolioContext from '../../../context/context';

gsap.registerPlugin(ScrollTrigger);

const Careers = () => {
  const [careers, setCareers] = React.useState([]);
  const { bootstrapBreakpoints } = React.useContext(PortfolioContext);
  // const email = 'jobs@craftablesoftware.com';
  const title = 'Careers';
  // const text = `We are always looking for talent. If none of the offers below quite matches your skills, please send an application to: <a href='mailto:${email}'>${email}</a>`;

  const { animationConfigSeeAllbtn } = React.useContext(PortfolioContext);
  React.useEffect(() => {
    gsap.from(`.seeAll-btn`, {
      ...animationConfigSeeAllbtn,
      scrollTrigger: {
        trigger: `.seeAll-btn`,
        start: 'top 80%',
        end: 'bottom 0%',
        toggleActions: 'play none none none',
      },
    });

    // async function fetchTitle(url) {
    // 	const response = await fetch(url)
    // 	const data = await response.json()
    // 	// setCareers(data)
    // 	const dataTemp = data.map(x => x * 4)
    // 	setCareers(data)
    // 	console.log({careers})
    // }

    // fetchTitle('https://localhost:3000/jobs')

    // DUMMY TEMP
    setCareers([
      {
        applyTo: 'https://apply.workable.com/craftable-software/j/5A2FD1D58A/apply/',
        id: nanoid(),
        office: 'Office in Porto or Aveiro, Portugal',
        remote: true,
        status: true,
        title: 'Dotnet Engineer',
        icon: 'dotnet-icon',
      },
      {
        applyTo: 'https://apply.workable.com/craftable-software/j/A5F3F29088/apply/',
        id: nanoid(),
        office: 'Office in Porto or Aveiro, Portugal',
        remote: true,
        status: true,
        title: 'ReactJs Engineer',
        icon: 'react-icon',
      },
      {
        applyTo: 'https://apply.workable.com/craftable-software/j/77F3A16C1E/apply/',
        id: nanoid(),
        office: 'Office in Porto or Aveiro, Portugal',
        remote: true,
        status: true,
        title: 'Java Software Engineer',
        icon: 'java-icon',
      },
      {
        applyTo: 'mailto:jobs@craftablesoftware.com?subject=Spontaneous Application',
        id: nanoid(),
        office: 'Office in Porto or Aveiro, Portugal',
        remote: true,
        status: true,
        title: 'Spontaneous Application',
        icon: 'craftable-jobs-icon',
      },
      // {
      // 	applyTo: 'batatinha.factorialhr.pt/job_postings/java-developer-3214',
      // 	id: nanoid(),
      // 	office: 'Avero, Portugal',
      // 	remote: true,
      // 	status: true,
      // 	title: 'Job'
      // },
    ]);
  }, []);

  // MASONRY BREAKPOINTS
  const breakpointColumnsObj = {
    default: 2,
    [bootstrapBreakpoints.lg]: 1,
  };

  const handleAllPositionsClick = () => {
    sendEvent({
      action: "Click",
      category: `AllPositions on Careers`,
      label: "Button"
    });
  }

  return (
    // id={props.id}
    <section id="careers" className="page-section">
      <span id="careerssanchor"></span>
      <MainWidth>
        <Container fluid className="nopadding">
          <Row className="careers__wrapper justify-content-md-center nopadding">
            <Col lg={12} className="nopadding-mobile">
              <header>
                <h2 className="mb-4 careers__title">{title}</h2>
                <p className="careers__paragraph">
                  We’re always looking for talent! Here are some of the positions we’re currently
                  hiring for.
                </p>
                {/* <p className='careers__text mb-5'>{ReactHtmlParser(text)}</p> */}
              </header>
              <div className="careers__grid">
                {careers.map((jobItem) => (
                  <JobCard key={jobItem.id} jobItem={jobItem} />
                ))}
              </div>
            </Col>
            <a
              className={'seeAll-btn'}
              href={'https://jobs.craftablesoftware.com/'}
              title="See all positions"
              onClick={handleAllPositionsClick}
            >
              See all positions
            </a>
          </Row>
        </Container>
      </MainWidth>
    </section>
  );
};

export default Careers;

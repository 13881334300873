import React from 'react';
import MainWidth from '../../Common/MainWidth';

interface IData {
  title: string;
  description: string;
  text: string;
}

const data: IData = {
  title: `Services`,
  description: `We help companies deliver complex products with the help of great people.`,
  text: `With clients in Europe and America, our boutique software house provides skilled teams, 100% dedicated to develop and deliver your software projects. We specialize in nearshore projects with a flare for quality, reliability and scalability in a highly transitional environment.`,
};

const Services = () => {
  const { title, description, text } = data;
  return (
    <section id="servicessection" className="page-section">
      <MainWidth>
        <h2 className="services_title title-font-size">{title}</h2>
        <h2 className="services_descr title-font-size">{description}</h2>
        <p className="services_text text-font-size">{text}</p>
      </MainWidth>
    </section>
  );
};

export default Services;

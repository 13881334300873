import React, { useCallback, useContext, useEffect, useState } from 'react';
import PortfolioContext from '../../context/context';
import { institutionalData } from '../../mock/data';
import Blog from './Blog';
import Careers from './Careers';
import Contacts from './Contacts';
import Hero, { IHero } from './Hero';
import Institutional, { IInstitutional } from './Institutional';
import InstitutionalII from './Institutional/index_II';
import Services from './Services';
import Svg from './SVG';
import TechnicalValues from './TechnicalValues';
import Timeline from './Timeline';

const HomePage = () => {
  const { setIsHomePage, posts, isBlog } = useContext(PortfolioContext);

  useEffect(() => {
    setIsHomePage(true);
  }, []);

  const [svgComponent, setSvgComponent] = useState<JSX.Element | null>(null);
  const [isHeroLoaded, setIsHeroLoaded] = useState<boolean>(false);
  const [isInstitutionalsLoaded, setIsInstitutionalsLoaded] = useState<boolean>(false);
  const [institutionalsLoadedCount, setInstitutionalsLoadedCount] = useState<number>(0);

  const wrappersetIsHeroLoaded = useCallback(
    (val) => {
      setIsHeroLoaded(val);
    },
    [setIsHeroLoaded]
  );

  const wrappersetIsInstitutionalsLoaded = useCallback(() => {
    setInstitutionalsLoadedCount((prevState) => prevState + 1);
  }, [setInstitutionalsLoadedCount]);

  useEffect(() => {
    if (institutionalsLoadedCount >= institutionalData.length) {
      setIsInstitutionalsLoaded(true);
    }
  }, [institutionalsLoadedCount]);

  useEffect(() => {
    if (isHeroLoaded && isInstitutionalsLoaded) {
      setSvgComponent(<Svg />);
    } else {
      setSvgComponent(null);
    }
  }, [isHeroLoaded, isInstitutionalsLoaded]);

  const HeroProps: IHero = {
    setLoaded: wrappersetIsHeroLoaded,
  };

  return (
    <>
      {svgComponent}
      <div id="heroabout">
        <Hero {...HeroProps} />
        <div id="values">
          {institutionalData.slice(0, 1).map((comp) => {
            const InstitutionalProps: IInstitutional = {
              comp,
              setLoaded: wrappersetIsInstitutionalsLoaded,
            };
            return <Institutional key={comp.idx} {...InstitutionalProps} />;
          })}
          {institutionalData.slice(1, 2).map((comp) => {
            const InstitutionalProps: IInstitutional = {
              comp,
              setLoaded: wrappersetIsInstitutionalsLoaded,
            };
            return <InstitutionalII key={comp.idx} {...InstitutionalProps} />;
          })}
          {institutionalData.slice(2, 3).map((comp) => {
            const InstitutionalProps: IInstitutional = {
              comp,
              setLoaded: wrappersetIsInstitutionalsLoaded,
            };
            return <Institutional key={comp.idx} {...InstitutionalProps} />;
          })}
        </div>
      </div>

      <span id="services">
        <Services />
        <Timeline />
        <TechnicalValues />
      </span>
      <span id="blog">{isBlog && <Blog articles={posts} />}</span>

      <Careers />
      <Contacts />
    </>
  );
};

export default HomePage;

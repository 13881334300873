import React from 'react';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Icon from '../../Common/Icon';
import PortfolioContext from '../../../context/context';

gsap.registerPlugin(ScrollTrigger);

const JobCard = ({ jobItem }) => {
  const { id, title, applyTo, office, remote, status, icon } = jobItem;
  const apply = 'Apply now';

  const { animationConfig } = React.useContext(PortfolioContext);
  React.useEffect(() => {
    gsap.from(`.job-card-${id}`, {
      ...animationConfig,
      scrollTrigger: {
        trigger: `.job-card-${id}`,
        start: 'top 80%',
        end: 'bottom 0%',
        toggleActions: 'play none none none',
      },
    });
  }, []);

  return (
    <div className={`job-card text-dark job-card job-card-${id}`} title={title}>
      <div className="card-body job-card_body d-flex flex-column flex-md-row ">
        <div className="job-card__icon-holder d-flex justify-content-center align-items-center">
          {
            icon ? (
              <i className={`job-card__icon`}>
                <Icon name={`${icon}`} />
              </i>
            ) : (
              <i className={'job-card__icon'}>
                <Icon name="logo" />
              </i>
            )
            // <img className='job-card__icon' alt='Craftable Icon' src={craftableIcon} />
          }
        </div>
        <div className="job-card__info">
          <h3 className="card-title job-card__title">{title}</h3>

          {remote && (
            <div className="job-card_line">
              <i className="job-card_icon-holder">
                <Icon name="remote" />
              </i>
              Remote friendly
            </div>
          )}

          {office && (
            <div className="job-card_line">
              <i className="job-card_icon-holder">
                <Icon name="location" />
              </i>
              {office}
            </div>
          )}

          {status && (
            <div className="job-card_line">
              <i className="job-card_icon-holder">
                <Icon name="status" />
              </i>
              Full-time
            </div>
          )}
          <div>
            <a href={applyTo} title={apply} className="job-card__btn">
              {apply}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobCard;

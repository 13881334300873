import React from 'react';

const TeamWork = () => (
  <svg
    version="1.1"
    id="projectsImage"
    x="0px"
    y="0px"
    viewBox="0 0 259 284"
    className="inst-wrapper__image_II"
  >
    <g>
      <path
        className="st0"
        d="M254.6,82.6c-1.9-1.9-4.9-1.9-6.7,0l-11.1,11.1l-60.6-60.6L187.3,22c1.9-1.9,1.9-4.9,0-6.7
                    c-1.9-1.9-4.9-1.9-6.7,0l-14.5,14.5c-0.9,0.9-1.4,2.1-1.4,3.4s0.5,2.5,1.4,3.4l2.7,2.7c-2.8,2.4-6,4.4-9.4,5.9
                    c-5.4,2.3-10.2,5.5-14.3,9.6l-9.5,9.5c-1.9,1.9-1.9,4.9,0,6.7c0.9,0.9,2.1,1.4,3.4,1.4s2.4-0.5,3.4-1.4l9.5-9.5
                    c3.2-3.2,7-5.8,11.3-7.6c4.6-1.9,8.8-4.6,12.4-7.9l48.3,48.3c-2.7,3.6-4.6,7.7-5.7,12c-1.4,5.5-4.2,10.5-8.2,14.4l-8.9,8.9
                    c-1.9,1.9-1.9,4.9,0,6.7c0.9,0.9,2.1,1.4,3.4,1.4c1.2,0,2.4-0.5,3.4-1.4l8.9-8.9c5.2-5.2,8.9-11.7,10.7-18.9
                    c0.7-2.7,1.8-5.2,3.3-7.5l2.8,2.8c0.9,0.9,2.1,1.4,3.4,1.4s2.5-0.5,3.4-1.4l14.5-14.5C256.5,87.5,256.5,84.5,254.6,82.6z"
      />
      <path
        className="st0"
        d="M116.2,214.3l-7,7c-4,4-9,6.8-14.4,8.2c-4.4,1.1-8.5,3-12,5.7l-48.3-48.3c3.3-3.7,6-7.9,7.9-12.4
                    c1.8-4.2,4.4-8,7.6-11.3l7.5-7.5c1.9-1.9,1.9-4.9,0-6.7c-1.9-1.9-4.9-1.9-6.7,0l-7.5,7.5c-4.1,4.1-7.4,8.9-9.6,14.3
                    c-1.5,3.5-3.5,6.6-5.9,9.4l-2.7-2.7c-0.9-0.9-2.1-1.4-3.4-1.4c-1.3,0-2.5,0.5-3.4,1.4L3.9,192c-1.9,1.9-1.9,4.9,0,6.7
                    c1.9,1.9,4.9,1.9,6.7,0l11.1-11.1l60.6,60.6l-11.1,11.1c-1.9,1.9-1.9,4.9,0,6.7c0.9,0.9,2.1,1.4,3.4,1.4c1.2,0,2.4-0.5,3.4-1.4
                    l14.5-14.5c1.9-1.9,1.9-4.9,0-6.7l-2.8-2.8c2.3-1.5,4.8-2.7,7.5-3.3c7.1-1.8,13.7-5.5,18.9-10.7l7-7c1.9-1.9,1.9-4.9,0-6.7
                    C121.1,212.4,118.1,212.4,116.2,214.3z"
      />
      <path
        id="hand_to_center"
        className="st0"
        style={{ transform: 'translate(50px, 50px)' }}
        d="M235.3,172.6c-1.9-1.9-4.9-1.9-6.7,0l-2.7,2.7c-2.4-2.8-4.4-6-5.9-9.4c-2.3-5.4-5.5-10.2-9.6-14.3L187.8,129
                    l0,0L152.7,94c-5.4-5.4-14.1-5.4-19.5,0l-0.3,0.3c-1.1,1.1-1.9,2.3-2.6,3.6c-5.4-5.1-13.9-5-19.2,0.3l-0.3,0.3
                    c-1.3,1.3-2.3,2.8-3,4.5c-5.1-2.2-11.2-1.3-15.3,2.9l-0.3,0.3c-4.6,4.6-5.3,11.7-2,17c-1.4,0.7-2.7,1.6-3.9,2.7l-0.3,0.3
                    c-5.4,5.4-5.4,14.1,0,19.5l23.2,23.2c-2.9,0.8-5.6,2.4-7.9,4.6l-0.1,0.1c-3.7,3.7-3.7,9.7,0,13.4l36.3,36.3
                    c5.2,5.2,11.7,8.9,18.9,10.7c2.7,0.7,5.2,1.8,7.5,3.3l-2.8,2.8c-1.9,1.9-1.9,4.9,0,6.7l14.5,14.5c0.9,0.9,2.1,1.4,3.4,1.4
                    c1.2,0,2.4-0.5,3.4-1.4c1.9-1.9,1.9-4.9,0-6.7l-11.1-11.1l3.2-3.2c0,0,0,0,0,0s0,0,0,0l57.4-57.4l11.1,11.1c1.9,1.9,4.9,1.9,6.7,0
                    c1.9-1.9,1.9-4.9,0-6.7L235.3,172.6z M158.9,224.6c-5.5-1.4-10.5-4.2-14.4-8.2l-36.3-36.3l0.1-0.1c1.7-1.7,3.9-2.6,6.3-2.6
                    s4.6,0.9,6.3,2.6c0,0,0,0,0,0l2.6,2.6l5.5,5.5c0,0,0,0,0,0l12,12c0.9,0.9,2.1,1.4,3.4,1.4s2.4-0.5,3.4-1.4c1.9-1.9,1.9-4.9,0-6.7
                    L130,175.9l-2.6-2.6c0,0,0,0,0,0c0,0,0,0,0,0l-34.6-34.6c-1.7-1.7-1.7-4.4,0-6.1l0.3-0.3c1.7-1.7,4.4-1.7,6.1,0l32.9,32.9
                    c0,0,0,0,0,0l6.5,6.5c1.9,1.9,4.9,1.9,6.7,0c1.9-1.9,1.9-4.9,0-6.7L106,125.7c0,0,0,0,0,0l-6.9-6.9c-1.7-1.7-1.7-4.4,0-6.1l0.3-0.3
                    c1.7-1.7,4.4-1.7,6.1,0l22.7,22.7l22.7,22.7c0.9,0.9,2.1,1.4,3.4,1.4c1.2,0,2.4-0.5,3.4-1.4c1.9-1.9,1.9-4.9,0-6.7l-11.7-11.7
                    l-28.2-28.2c-0.8-0.8-1.3-1.9-1.3-3c0-1.1,0.4-2.2,1.3-3l0.3-0.3c0.8-0.8,1.9-1.3,3-1.3c1.1,0,2.2,0.4,3,1.3l13.8,13.8l30.2,30.2
                    c0.9,0.9,2.1,1.4,3.4,1.4c1.2,0,2.4-0.5,3.4-1.4c1.9-1.9,1.9-4.9,0-6.7l-4.1-4.1c0,0,0,0,0,0l-22.1-22.1l-8.8-8.8
                    c-1.7-1.7-1.7-4.4,0-6.1l0.3-0.3c1.7-1.7,4.4-1.7,6.1,0l35.1,35.1l0,0l22.6,22.6c3.2,3.2,5.8,7,7.6,11.3c1.9,4.6,4.6,8.8,7.9,12.4
                    l-48.3,48.3C167.3,227.6,163.2,225.7,158.9,224.6z"
      />
      <path
        className="st0"
        d="M18.4,103.9c0.9,0.9,2.1,1.4,3.4,1.4c1.3,0,2.5-0.5,3.4-1.4l2.8-2.8c1.5,2.3,2.7,4.8,3.3,7.5
                    c1.8,7.1,5.5,13.7,10.7,18.9l8.9,8.9c0.9,0.9,2.1,1.4,3.4,1.4c1.2,0,2.4-0.5,3.4-1.4c1.9-1.9,1.9-4.9,0-6.7l-8.9-8.9
                    c-4-4-6.8-9-8.2-14.4c-1.1-4.4-3-8.5-5.7-12L83,45.9c3.7,3.3,7.9,6,12.4,7.9c4.2,1.8,8,4.4,11.3,7.6l9.5,9.5
                    c0.9,0.9,2.1,1.4,3.4,1.4c1.2,0,2.4-0.5,3.4-1.4c1.9-1.9,1.9-4.9,0-6.7l-9.5-9.5c-4.1-4.1-8.9-7.4-14.3-9.6
                    c-3.5-1.5-6.6-3.5-9.4-5.9l2.7-2.7c1.9-1.9,1.9-4.9,0-6.7L78,15.3c-1.9-1.9-4.9-1.9-6.7,0c-1.9,1.9-1.9,4.9,0,6.7l11.1,11.1
                    L24.9,90.6c0,0,0,0,0,0s0,0,0,0l-3.2,3.2L10.6,82.6c-1.9-1.9-4.9-1.9-6.7,0c-1.9,1.9-1.9,4.9,0,6.7L18.4,103.9z"
      />
    </g>
  </svg>
);

export default TeamWork;

import React from 'react';
import MainWidth from '../../Common/MainWidth';
import Icon from '../../Common/Icon';
import { title, technicalValues } from './data';

const TechnicalValues = () => {
  return (
    <section id="technical" className="page-section">
      <MainWidth>
        <h2 className="technical_title title-font-size">{title}</h2>
        {technicalValues.map((item) => (
          <Card key={item.id} {...item} />
        ))}
      </MainWidth>
    </section>
  );
}

const Card = ({ icon, value }) => {
  return (
    <div className="technical_card">
      <div className="technical_card_icon">
        <Icon name={icon} />
      </div>
      <h3 className="technical_card_title">{value}</h3>
    </div>
  );
};

export default TechnicalValues;

import React, { useEffect } from 'react';
import ReactHtmlParser from 'html-react-parser';
import { Container, Row, Col } from 'react-bootstrap';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import MainWidth from '../../Common/MainWidth';
import InstitutionalImage from './components/InstitutionalImage';

gsap.registerPlugin(ScrollTrigger);

export interface IInstitutional {
  comp: { idx: string; id: string; title: string; text: string };
  setLoaded: () => void;
}

const InstitutionalII = ({ comp, setLoaded }: IInstitutional) => {
  const { id, title, text } = comp;

  useEffect(() => {
    setLoaded();
    const container = document.getElementById(`${id}Image`);
    const addAnimation = (add = true) => {
      if (add) {
        container.classList.add('animate');
      } else {
        container.classList.remove('animate');
      }
    };

    gsap.from(`#svg-${id}`, {
      scrollTrigger: {
        trigger: `.inst-section_bg-${id}`,
        start: 'top 40%',
        end: 'bottom 10%',
        toggleActions: 'play none none none',
        onEnter: () => addAnimation(true),
      },
    });
  }, []);

  return (
    <div id="inst_bg">
    <section id={`${id}-holder`} className={`inst-section inst-section_bg-${id}`}>
      <MainWidth>
        <Container id={id} className="inst-section__container nopadding-mobile " fluid>
          <Row className="inst-section__wrapper inst-wrapper align-items-end ">
            <Col xl={6} lg={12} className="nopadding-mobile">
              <div id={`svg-${id}`} className="inst-wrapper__imgholder_II">
                <InstitutionalImage image={id} className="inst-wrapper__image_II" />
              </div>
            </Col>
            <Col xl={8} lg={12} className="nopadding-mobile">
              <div className={`inst-wrapper__info_II inst-section__animation-${id}`}>
                <h2 className="inst-wrapper__text-title_II">{title}</h2>
                <p className="inst-wrapper__text_II">{ReactHtmlParser(text)}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </MainWidth>
    </section>
    </div>
  );
};

export default InstitutionalII;

import React, { useEffect, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import { sendEvent } from '../../../utils/ga4';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Icon from '../../Common/Icon';
import * as S from './styled';
import { Modal } from 'react-bootstrap';
import Form from '../../Form';

gsap.registerPlugin(ScrollTrigger);

interface IData {
  id: string;
  title: string;
  text: string;
  iconName: string;
}

const data: IData[] = [
  {
    id: nanoid(),
    title: 'Meet the challenge',
    text: `Complexity brings a smile to our face. We need to thrive and stay sharp, trying out new ways of working and always open to being challenged.`,
    iconName: 'mountains',
  },
  {
    id: nanoid(),
    title: 'Start small, scale big',
    text: `Build a core development team and scale it as you need. Our experts are Software Engineers, Quality Assurance, Product Owners, DevOps, Cybersecurity Specialists, among others.`,
    iconName: 'matroska',
  },
  {
    id: nanoid(),
    title: 'Get deep tech expertise ',
    text: `Trust our software cybersecurity qualities. We allocate the most qualified team for your project, giving them support to get it done.`,
    iconName: 'stars',
  },
];

const Timeline = () => {
  const [showModal, setShowModal] = useState(false);
  const revealRefs = useRef([]);
  revealRefs.current = [];

  useEffect(() => {
    revealRefs.current.forEach((element, index) => {
      const horizontal = {
        x: index % 2 === 0 ? '200' : '-200',
      };

      gsap.from(element, {
        duration: 1,
        ...horizontal,
        opacity: 0,
        ease: 'ease-in',
        scrollTrigger: {
          id: `section-${index + 1}`,
          trigger: element,
          start: 'top 50%',
          end: 'bottom 10%',
          toggleActions: 'play none none none',
          // markers: true,
        },
      });
    });
  }, []);

  const addToRefs = (el) => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  };

  const handleCTAClick = () => {
    sendEvent({
      action: "Click",
      category: "StartProject on Timeline",
      label: "Button"
    });

    handleOpenModal();
  }

  const handleCloseModal = () => setShowModal(false);
  const handleOpenModal = () => setShowModal(true);

  return (
    <S.TimelineSection id="timeline" className="page-section">
      <p className="timeline-title sub-title-font-size-secondary">Our approach</p>
      <S.TimelineContainer id="timeline_container">
        <S.VerticalTimeline>
          {data.map((item: IData, index) => {
            const { id, title, text, iconName } = item;
            const rowReverse = index % 2 === 0 && 'flex-lg-row-reverse';
            return (
              <S.TimelineLi
                key={id}
                ref={addToRefs}
                className={`flex-column flex-lg-row ${rowReverse}`}
              >
                <S.TopBlock>
                  <div className="vertical-timeline-element-image">
                    <Icon name={iconName} />
                  </div>
                </S.TopBlock>
                <S.TimelineIcon>
                  <S.Step>Step {index + 1}</S.Step>
                  <Icon name="arrow-down" />
                </S.TimelineIcon>
                <S.BottomBlock>
                  <h4 className="vertical-timeline-element-subtitle sub-title-font-size-secondary">
                    {title}
                  </h4>
                  <br />
                  <p className="text-font-size">{text}</p>
                </S.BottomBlock>
              </S.TimelineLi>
            );
          })}
          <S.TimelineLicta ref={addToRefs} className={`flex-column flex-lg-row`}>
            <S.ButtonBlock>
              <S.Step4>Step 4</S.Step4>
              <a
                onClick={handleCTAClick}
                id="roadmap-cta"
              >
                <i>
                  <Icon name="cta-desktop" />
                  <Icon name="cta-tablet" />
                  <Icon name="cta-mobile" />
                </i>
              </a>
              <p className="text-font-size timeline-text">
                Reduce development costs and optimise resource management by choosing the best
                solution, from the start, to solve your problem.
              </p>
            </S.ButtonBlock>
          </S.TimelineLicta>
        </S.VerticalTimeline>
      </S.TimelineContainer>
      <Modal show={showModal} onHide={handleCloseModal} size="xl" centered>
        <Form onClose={handleCloseModal}/>
      </Modal>
    </S.TimelineSection>
  );
};

export default Timeline;

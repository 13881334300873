import React from 'react';

const Cool = () => (
  <svg
    version="1.1"
    id="contactImage"
    x="0px"
    y="0px"
    viewBox="0 0 259 284"
    className="inst-wrapper__image"
  >
    <g id="Welcome">
      <g id="Group-13" transform="translate(-311.000000, -2151.000000)">
        <g transform="translate(311.000000, 2151.000000)">
          <g id="hand_to_rotate">
            <g id="Group-3" transform="translate(0.000000, 37.000000)">
              <g id="Clip-2" />
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter"
                  filterUnits="userSpaceOnUse"
                  x="0"
                  y="0.3"
                  width="258.8"
                  height="246.7"
                >
                  <feColorMatrix
                    type="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                  />
                </filter>
              </defs>
              <mask
                maskUnits="userSpaceOnUse"
                x="0"
                y="0.3"
                width="258.8"
                height="246.7"
                id="mask-3_1_"
              >
                <g className="st0">
                  <polygon
                    id="path-2_1_"
                    className="st1"
                    points="0,0.3 258.8,0.3 258.8,247 0,247 						"
                  />
                </g>
              </mask>
              <path
                id="Fill-1"
                className="st2"
                d="M245.4,108.7L136.3,217.5c-27.5,27.4-72.2,27.4-99.7,0l-4.4-4.4
                                    c-16.3-16.3-22.9-38.6-19.9-59.8c0-0.1,0-0.1,0-0.2c0.5-4.4,1.4-9.4,2.4-15.2c1.2-6.4,2.5-13.7,3.6-21.9
                                    c2.7-21.7,2.9-50.2-8.5-87c-2.1-6.9-0.2-15.4,7.5-18.6c3.5-1.4,7.3-1.4,10.7,0c3.5,1.4,6.1,4.1,7.6,7.6l1.9,4.4
                                    c9.7,25.1,13.5,48.4,13.9,59.4c0,0.6,0,1.2,0,1.8c0,2.5,2,4.5,4.4,4.5c0.1,0,0.1,0,0.2,0c1.2,0,2.4-0.6,3.3-1.4l0.7-0.7l5.6-5.5
                                    c0.5,5.1,2.6,10,6.3,14c4.4,4.6,10.3,7.3,16.7,7.5c-4.2,8.8-2.9,19.6,4,26.9c4.5,4.7,10.6,7.4,17.1,7.5c3.4,0,6.8-0.6,9.8-1.9
                                    c0,5.9,2.1,11.7,6.4,16.2c4.5,4.7,10.6,7.4,17.1,7.5c5.2,0.1,10.2-1.5,14.3-4.5c0.5-0.2,1-0.5,1.4-0.9l1.6-1.6l27.9-27.8
                                    l35.5-35.4c5.8-5.7,15.1-6.2,21.1-0.7C251.3,92.9,251.4,102.7,245.4,108.7 M79,67l27.6-27.5c5.9-5.8,15.4-5.8,21.3,0
                                    c5.9,5.8,5.9,15.3,0,21.2L100,88.5c-2.9,2.9-6.7,4.4-10.8,4.4c-4.1-0.1-7.9-1.7-10.7-4.7C72.9,82.3,73.2,72.9,79,67 M182,95.7
                                    c5.9,5.8,5.9,15.3,0,21.2l-27.9,27.8c-2.9,2.9-6.7,4.4-10.8,4.4c-4.1-0.1-7.9-1.7-10.7-4.7c-5.5-5.8-5.3-15.3,0.6-21.2
                                    l27.6-27.5C166.6,89.8,176.1,89.8,182,95.7 M156,66.5c5.9,5.8,5.9,15.3,0,21.2l-35.2,35.1c-2.9,2.9-6.7,4.4-10.8,4.4
                                    c-4.1-0.1-7.9-1.7-10.7-4.7c-5.5-5.8-5.3-15.3,0.6-21.2l6.6-6.5l28.4-28.3C140.6,60.7,150.1,60.7,156,66.5 M217.8,81.2
                                    l-22.6,22.5c-0.6-5.2-2.9-10.3-6.9-14.3c-5.3-5.3-12.6-7.6-19.6-6.9c1.8-7.8-0.4-16.2-6.4-22.3c-5.8-5.8-13.8-8-21.3-6.6
                                    c1.1-7.2-1.2-14.9-6.8-20.4c-9.4-9.3-24.6-9.3-34,0L59.9,73.3c-1.5-13.9-6.1-34-14-54.2c0,0,0-0.1-0.1-0.1L44,14.6
                                    C39.2,2.9,25.7-2.8,14,2C2.7,6.6-2.6,19,1.3,31.6c15.1,48.7,9,81.1,4.7,104.7c-1.1,5.9-2,11-2.6,15.7c0,0.1,0,0.2,0,0.3
                                    c-3.3,23.8,4.2,48.8,22.5,67l4.4,4.4c31,30.9,81.4,30.9,112.4,0l109.1-108.8c9.4-9.3,9.4-24.5,0-33.9
                                    C242.4,71.8,227.2,71.8,217.8,81.2"
              />
            </g>
            <path
              id="Fill-4"
              className="st3"
              d="M61.1,233c-1.5,0-3-0.8-3.7-2.3c-1-2-0.1-4.4,1.9-5.4c17.7-8.4,28-28.8,25-49.7
                                c-0.3-2.2,1.2-4.3,3.5-4.6c2.2-0.3,4.3,1.2,4.6,3.4c3.5,24.4-8.6,48.3-29.5,58.1C62.3,232.9,61.7,233,61.1,233"
            />
          </g>

          <g id="lines" style={{ opacity: 0 }}>
            <g id="Group-8" transform="translate(112.000000, 0.000000)">
              <g id="Clip-7" />
              <defs>
                <filter
                  id="Adobe_OpacityMaskFilter_1_"
                  filterUnits="userSpaceOnUse"
                  x="0.4"
                  y="0.1"
                  width="10.2"
                  height="51.8"
                >
                  <feColorMatrix
                    type="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0"
                  />
                </filter>
              </defs>
              <mask
                maskUnits="userSpaceOnUse"
                x="0.4"
                y="0.1"
                width="10.2"
                height="51.8"
                id="mask-5_1_"
              >
                <g className="st4">
                  <polygon
                    id="path-4_1_"
                    className="st1"
                    points="0.4,0.1 10.6,0.1 10.6,51.9 0.4,51.9 						"
                  />
                </g>
              </mask>
              <path
                id="Fill-6"
                className="st5"
                d="M5.5,51.9c-2.8,0-5.1-2.3-5.1-5.2V5.3c0-2.9,2.3-5.2,5.1-5.2s5.1,2.3,5.1,5.2v41.4
                                        C10.6,49.5,8.3,51.9,5.5,51.9"
              />
            </g>
            <path
              id="Fill-9"
              className="st3"
              d="M77.7,56c-1.6,0-3.1-0.7-4.2-2L53.1,27.5c-1.8-2.3-1.4-5.6,0.9-7.4c2.3-1.8,5.6-1.4,7.4,0.9
                                    l20.5,26.4c1.8,2.3,1.4,5.6-0.9,7.4C80,55.6,78.9,56,77.7,56"
            />
            <path
              id="Fill-11"
              className="st3"
              d="M156.3,56c-1.1,0-2.3-0.4-3.2-1.1c-2.3-1.8-2.7-5.1-0.9-7.4L172.6,21c1.8-2.3,5.1-2.7,7.4-0.9
                                    c2.3,1.8,2.7,5.1,0.9,7.4L160.4,54C159.4,55.3,157.8,56,156.3,56"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Cool;
